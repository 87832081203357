var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(!_vm.alerts_list.length && !_vm.events_list.length)?_c('v-layout',{staticClass:"pa-3 blue-rounded-card",attrs:{"justify-center":"","align-center":""}},[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('There are no incidents for this cabinet')))])]):_c('v-layout',{class:_vm.table_view ? '' : 'general-view-toggle-style',staticStyle:{"background-color":"transparent"},attrs:{"column":""}},[_c('v-layout',{staticClass:"mt-3",attrs:{"row":"","wrap":""}},[_c('v-flex',[_c('v-switch',{staticClass:"pa-0 my-0",class:_vm.lang === 'en' ? 'mr-1 ml-0' : 'ml-1 mr-0',attrs:{"hide-details":"","label":_vm.table_view && _vm.$t('Table') || _vm.$t('General'),"color":"#292F7D"},model:{value:(_vm.table_view),callback:function ($$v) {_vm.table_view=$$v},expression:"table_view"}})],1),(_vm.table_view)?_c('v-flex',{staticClass:"clear-btn",attrs:{"d-flex":"","justify-end":""}},[_c('v-btn',{staticClass:"white--text text-transform-none",attrs:{"color":"#292F7D","round":""},on:{"click":_vm.clearSelections}},[_vm._v(_vm._s(_vm.$t('Clear Selection')))])],1):_vm._e()],1),(_vm.table_view)?[_c('IncidentsTableView',{ref:"table",staticClass:"my-3",attrs:{"alert_types":_vm.alert_types,"event_types":_vm.event_types},on:{"handleIncidentClicked":_vm.handleIncidentClicked}})]:[_vm._l((Object.entries(_vm.alert_types)),function(ref,index){
var alert_type = ref[0];
var alert_data = ref[1];
return _c('v-layout',{key:("alert-" + index),staticClass:"my-3",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"mb-2",attrs:{"xs12":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('div',{class:("prioritySign " + (alert_data.priority_color) + "_alert")}),_c('h3',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.$t(_vm.prop_utils.getFieldLowerToUpper(alert_type))))])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('CabinetAlertsTable',{attrs:{"data":alert_data.list,"project":_vm.project,"cabinetData":_vm.incidents_data,"type":alert_type,"headers_list":_vm.alerts_with_header_1.includes(alert_type)
              ? _vm.alertHeaders[0]
                : _vm.alerts_with_header_2.includes(alert_type)
                  ? _vm.alertHeaders[1]
                  : _vm.alerts_with_header_3.includes(alert_type)
                    ? _vm.alertHeaders[2]
                    : _vm.single_unit_probability_alert_types.includes(alert_type)
                      ? _vm.alertHeaders[3]
                      : _vm.alertHeaders[4]},on:{"handleIncidentClicked":_vm.handleIncidentClicked}})],1)],1)}),_vm._l((Object.entries(_vm.event_types)),function(ref,index){
                      var event_type = ref[0];
                      var event_data = ref[1];
return _c('v-layout',{key:("event-" + index),staticClass:"my-3",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"mb-2",attrs:{"xs12":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('div',{staticClass:"prioritySign info_alert"}),_c('h3',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.$t(_vm.prop_utils.getFieldLowerToUpper(event_type))))])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('CabinetEventsTable',{attrs:{"data":event_data.list,"cabinetData":_vm.incidents_data,"type":event_type,"headers_list":event_data.category.toLowerCase() === _vm.event_abnormal
              ? _vm.eventHeaders[0]
                : event_data.category.toLowerCase() === _vm.event_consumption_during_day
                  ? _vm.eventHeaders[1]
                  : _vm.eventHeaders[2]},on:{"handleIncidentClicked":_vm.handleIncidentClicked}})],1)],1)})],(_vm.incident_details_dialog_open)?_c('IncidentDetailsDialog',{attrs:{"dialog_data":_vm.selected_incident,"expected_consumption":_vm.incidents_data.maxA},on:{"setIncidentDialog":_vm.setIncidentDialogIsOpen,"handleIncidentClosed":_vm.setCabinetIncidentsData}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }