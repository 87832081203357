































































































import TabsInfoMixin from '../../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Reports, Global } from '@/store';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import CabinetAlertsTable from './CabinetAlertsTable.vue';
import CabinetEventsTable from './CabinetEventsTable.vue';
import IncidentDetailsDialog from './IncidentDetailsDialog.vue';
import { alerts_with_header_1, alerts_with_header_2, alerts_with_header_3, single_unit_alert_types, single_unit_probability_alert_types } from '@/store/modules/Global';
import { event_abnormal, event_consumption_during_day, event_consumption_anomalies } from '@/store/modules/Global';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import IncidentsTableView from './IncidentsTableView.vue';

@Component({
  components: {
    CabinetAlertsTable,
    CabinetEventsTable,
    IncidentsTableView,
    IncidentDetailsDialog
  }
})
export default class IncidentsTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;
  @Reports.Getter('cabinets_fixtures') cabinets_fixtures;

  // @ts-ignore
    @Prop() device;
    @Prop() incidents_data;
    @Prop() alerts_list;
    @Prop() events_list;

    loading = true;
    alert_types = {};
    event_types = {};
    prop_utils = PropUtils;
    incident_details_dialog_open = false;
    selected_incident = null;
    alerts_with_header_1 = alerts_with_header_1;
    alerts_with_header_2 = alerts_with_header_2;
    alerts_with_header_3 = alerts_with_header_3;
    single_unit_alert_types = single_unit_alert_types;
    single_unit_probability_alert_types = single_unit_probability_alert_types;
    event_abnormal = event_abnormal;
    event_consumption_during_day = event_consumption_during_day;
    table_view = false;

    mounted() {
      this.setCabinetIncidentsData();
    }

    handleIncidentClicked(alert){
      this.selected_incident = alert;
      this.setIncidentDialogIsOpen(true);
    }

    setIncidentDialogIsOpen(is_open){
      this.incident_details_dialog_open = is_open;
      if (!is_open) {
        this.selected_incident = null;
      }
    }

    get alertHeaders() {
      return [[], ['phase'], ['circuit_number'], ['circuit_number', 'pole', 'phase', 'probability'], ['circuit_number', 'pole', 'phase']];
    }

    get eventHeaders(){
      return [['phase', 'unusual_measure'], ['actual_consumption'], ['expected_consumption', 'actual_consumption', 'percentage_deviation']];
    }

    get timezone(){
      return this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    }

    setCabinetIncidentsData() {
      this.loading = true;
      this.setCabinetAlertTypes();
      this.setCabinetEventTypes();
      this.loading = false;
    }

    clearSelections(){
      this.$refs.table['clearSelections']();
    }

    @Watch('alerts_list')
    setCabinetAlertTypes(){
      const alert_types = {};
      const project_alerts = cloneDeep(this.alerts_list);
      const single_unit_alerts = this.single_unit_alert_types.concat(this.single_unit_probability_alert_types);
      project_alerts.forEach((alert) => {
        alert.time = moment(alert.created_at).valueOf();
        alert.parsed_time = Utils.convertTimestamp(alert.time, 'DD/MM/YYYY HH:mm:ss', this.timezone);
        alert.parsed_title = this.$t(Utils.getTicketDescription(alert.title));
        alert.description = alert.description || alert.parsed_title;
        alert.parsed_type = this.$t(PropUtils.getFieldLowerToUpper(alert.alert_type));
        alert.parsed_priority = this.$t(Utils.getAlertPriority(alert.priority_id));
        alert.relative_time = Utils.translateRelativeTime(Utils.getRelativeTime(alert.time));

        const alert_type = `${alert.alert_type}`;
        if (single_unit_alerts.includes(alert_type)){
          if (alert.alert_type === 'daytime_light_fault') {
            alert.probability = alert.probability * 100;
            if (alert.probability >= 75){
              alert.bold = true;
            }
          }
          const device = this.$store.state.Reports.reportsList.find((device) => device.id === alert.device);
          if (device) {
            alert.circuit_number = device['meta.device'].circuit_number;
            alert.phase = device['meta.device'].phase;
            alert.pole = device['meta.device'].pole_number;
          }else return;
        }else if (alerts_with_header_2.includes(alert_type)) {
          alert.phase = Utils.convertNumberToRealPhase(alert.phase);
        }else if (alerts_with_header_3.includes(alert_type)) {
          alert.circuit_number = alert.circuit_number;
        }
        if (alert_types[alert_type]) {
          alert_types[alert_type].list.push(alert);
        }else {
          alert_types[alert_type] = { 
            priority_id: alert.priority_id,
            list: [alert],
            priority_color: alert.priority_id === 1 ? 'green' : alert.priority_id === 2 ? 'yellow' : 'red'
          };
        }
      });

      this.alert_types = alert_types;
    }
    
    @Watch('events_list')
    setCabinetEventTypes(){
      const event_types = {};
      const project_events = cloneDeep(this.events_list);
      project_events.forEach((event) => {
        event.time = moment(event.created_at).valueOf();
        event.parsed_time = Utils.convertTimestamp(event.time, 'DD/MM/YYYY HH:mm:ss', this.timezone);
        event.parsed_title = this.$t(Utils.getTicketDescription(event.title));
        event.description = event.description || event.parsed_title;
        event.parsed_type = this.$t(PropUtils.getFieldLowerToUpper(event.event_type));
        event.parsed_priority = this.$t('Info');
        event.relative_time = Utils.translateRelativeTime(Utils.getRelativeTime(event.time));

        if (event.consumption_category.toLowerCase() === event_abnormal) {
          event.phase = Utils.convertNumberToRealPhase(event.phase);
          event.unusual_measure = event.title.includes('Voltage') && `${event.value}V` || `${event.value}A`;
        }else if (event.consumption_category.toLowerCase() === event_consumption_anomalies) {
          event.expected_consumption = +this.incidents_data.maxA.toFixed(2) + 'A';
          event.actual_consumption = event.value + 'A';
          let percent = 0;
          if (typeof this.incidents_data.maxA === 'number' && this.incidents_data.maxA !== 0) {
            percent = (parseInt(event.value, 10) / this.incidents_data.maxA - 1) * 100;
          }
          event.percentage_deviation = percent.toFixed(2) + '%';
        }else {
          event.actual_consumption = event.value + 'A';
        }
        let event_type = `${event.event_type}`;
        if (!event_type && event.consumption_category === 'Abnormal data') {
          event_type = 'Power supply failure';
        }
        if (event_types[event_type]) {
          event_types[event_type].list.push(event);
        }else {
          event_types[event_type] = {};
          event_types[event_type].category = event.consumption_category;
          event_types[event_type].list = [event];
        }
      });

      this.event_types = event_types;
    }
}
