



















































import Utils from '@/modules/Utils';
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({})
export default class CabinetEventsTable extends Vue {

  @Global.State('lang') lang;

  @Prop() data;
  @Prop() cabinetData;
  @Prop() type;
  @Prop() headers_list;

  tableData = [];
  loading = true;
  sort = null;
  pagination = {
    rowsPerPage: 5,
    totalItems: 0
  };
  sortBy = 'time';
  descending = false;
    
  mounted() {
    this.generateEventTable();
  }

  @Watch('data')
  @Watch('type')
  generateEventTable(){
    this.tableData = cloneDeep(this.data);
    const time_header = this.headers.find((header) => header.hasOwnProperty('sort_field') && header['sort_field'] === 'time');
    this.changeSort(time_header);
    this.pagination.totalItems = this.tableData.length;
    this.loading = false;
  }

    openEvent(event) {
      this.$emit('handleIncidentClicked', event);
    }

    changeSort(header_data) {
      this.loading = true;
      if (header_data.hasOwnProperty('sortable') && !header_data.sortable){
        return;
      }

      if (this.sortBy === header_data.sort_field) {
        this.descending = !this.descending;
      }else {
        this.sortBy = header_data.sort_field;
        this.descending = false;
      }

      if (this.descending) {
        if (this.sortBy === 'time'){
          this.tableData.sort((event1, event2) => event2[this.sortBy] - event1[this.sortBy]);
        }else {
          this.tableData.sort((event1, event2) => event2[this.sortBy].toString().localeCompare(event1[this.sortBy].toString(), undefined, {numeric: true, sensitivity: 'base'}));
        }
      }else {
        if (this.sortBy === 'time'){
          this.tableData.sort((event1, event2) => event1[this.sortBy] - event2[this.sortBy]);
        }else {
          this.tableData.sort((event1, event2) => event1[this.sortBy].toString().localeCompare(event2[this.sortBy].toString(), undefined, {numeric: true, sensitivity: 'base'}));
        }
      }
      this.loading = false;
    }

    get headers() {
      const start_headers = [
        { text: 'Cabinet ID', sortable: false }
      ];

      const middle_headers = [
        { text: 'Event Description', sort_field: 'event_type', sortable: true },
        { text: 'Phase', sort_field: 'phase', sortable: true },
        { text: 'Unusual Measure', sort_field: 'unusual_measure', sortable: true },
        { text: 'Expected Consumption', sort_field: 'expected_consumption', sortable: true },
        { text: 'Actual Consumption', sort_field: 'actual_consumption', sortable: true },
        { text: 'Percentage Deviation', sort_field: 'percentage_deviation', sortable: true }
      ];

      const end_headers = [
        { text: 'Date Time', sort_field: 'time', sortable: true }
      ];
      return start_headers.concat(middle_headers.filter((header) => this.headers_list.includes(header.sort_field))).concat(end_headers);
    }
}
